export const SEND_OTP = {
  query: `mutation{
    sendOtp({0}){
      success
      sentAt
      error
      isUserExist
    }
  }`,
  responseName: 'sendOtp'
}
export const VERIFY_OTP = {
  query: `mutation{
  verifyOtp({0}){
    success
    error
  }
}`,
  responseName: 'verifyOtp'
}
export const SIGNUP = {
  query: `mutation{
  signUpWebApp({0}){
    user{
      id,
      token
    }
    errorMessage
    message
  }
}`,
  responseName: 'signUpWebApp'
}

export const GET_USERINFO = {
  query: `query {
  myInfo{
    id
    name
    birthday
    code
    email
    address
    gender
    phone
    token
    subUsers {
      nodes {
        id
        name
        birthday
        code
        email
        address
        gender
        phone
        userRelationType {
          dependentType
          protectorType
        }
      }
    }
  }
}`,
  responseName: 'myInfo'
}
export const LOGIN_QUERY = {
  query: `mutation {
      loginWebApp({0}) {
      user {
        id
        name
        birthday
        code
        email
        address
        gender
        phone
        token
        subUsers {
          nodes {
            id
            name
            birthday
            code
            email
            address
            gender
            phone
            userRelationType {
              dependentType
              protectorType
            }
          }
        }
      }
      message
      errorMessage
    }
  }`,
  responseName: 'loginWebApp'
}
export const GET_ONLINE_SERVICES = {
  query: `query {
  mainServices{
        name price id brandId doHomeService isPopular limitTime specialityIds
        image{
          thumb
        }
      }
  specialities {
    contentEn
    contentVi
    id
    image{
      thumb
    }
  }
}`,
  responseName: ['mainServices', 'specialities']
}
export const GET_LIST_DOCTOR = {
  query: `query { 
    doctors({0}){
      name id nextAvailableTime reviewAvg serviceTotalPrice degreeName subFee bio 
      photo{
        thumb large
      }
      doctorInfo{
        specialityContent
      }
      datetimeBooked {
        nodes {
          startTime date limitTime
        }
      }
      brand { id name }  
      userEducations{
        nodes{
          course university startYear endYear
        }
      }
      workHoursOffline{
        nodes {
        morningWorkFrom
        morningWorkTo
        morningActive
        afternoonWorkFrom
        afternoonWorkTo
        afternoonActive
        eveningWorkFrom
        eveningWorkTo
        eveningActive
        date
        scheduleType
        weekDay
        brandName
        brandId
      }}
      dayOffOffline{
        nodes {
        morningWorkFrom
        morningWorkTo
        morningActive
        afternoonWorkFrom
        afternoonWorkTo
        afternoonActive
        eveningWorkFrom
        eveningWorkTo
        eveningActive
        date
        scheduleType
        weekDay
        brandName
        brandId
      }}
      workHoursOnline {
        nodes {
        morningWorkFrom
        morningWorkTo
        morningActive
        afternoonWorkFrom
        afternoonWorkTo
        afternoonActive
        eveningWorkFrom
        eveningWorkTo
        eveningActive
        date
        scheduleType
        weekDay
        brandName
        brandId
      }}
      dayOffOnline {
        nodes {
        morningWorkFrom
        morningWorkTo
        morningActive
        afternoonWorkFrom
        afternoonWorkTo
        afternoonActive
        eveningWorkFrom
        eveningWorkTo
        eveningActive
        date
        scheduleType
        weekDay
        brandName
        brandId
      }
      }
      userJobs{
        nodes{
          workplace
          job start end now
        }
      }
      certificates{
        nodes{
          id
          name
          dateOfIssue
          placeOfIssue
        }
      }
    }
  }`,
  responseName: 'doctors'
}
export const BOOK_APPPOINTMENT_ON = {
  query: `mutation{0}{
    addAppointmentDraft({1} {2}){
    appointment{
      id
    }
    error errorMessage
  }
  }`,
  responseName: 'addAppointmentDraft'
}

export const BOOK_APPPOINTMENT_OFF = {
  query: `mutation{0}{
    addAppointment({1} {2}){
    appointment{
      id
    }
    error errorMessage
  }
  }`,
  responseName: 'addAppointment'
}
export const ADD_SUB_PATIENT = {
  query: `mutation {
    addSubPatient({0}){
      user {
        id name birthday code gender
        patientInfo { bloodGroup }
        userRelationType{
          dependentType
          protectorType
        }
      }
      error
    }
  }`,
  responseName: 'addSubPatient'
}
export const GET_RELATIONSHIP = {
  query: `query{
    userRelationTypesGroupDependent
  }`,
  responseName: 'userRelationTypesGroupDependent'
}
export const CHECK_PROMO_CODE_VALID = {
  query: `mutation{
    validateCoupon({0}){
      error
      errorMessage
      discountAmount
      description
    }
  }`,
  responseName: 'validateCoupon'
}
export const GENERATE_PAYMENT = {
  query: `mutation { generatePaymentUrl({0}) { error url errorMessage isFreeTransaction isTransferPayment request } }`,
  responseName: 'generatePaymentUrl'
}

export const GET_APPOINTMENTS = {
  query: `{
    myAppointments({0}){
      edges{
        node{
          startTime totalPaid date status serviceId id remarks doHomeService patientId cancelReason visitReason totalAmount
          clinic{
            name address
          }
          patient { id name dependentId photo {thumb} }
          doctor{
            name id photo { thumb large } 
            doctorInfo { specialityContent }
          }  attachments {
            nodes {
              id
              file {
                url
              }
            }
          }
          healthRecord {
            id code status patientId 
            bills {
              nodes {
                id
                total
              }
            }
          }
        }
      }
    }
  }`,
  responseName: 'myAppointments'
}

export const CANCEL_APPOINTMENT = {
  query: `mutation{
  cancelAppointment({0}){
    error,
    appointment{
      id,
      status
    }
  }
}`,
  responseName: 'cancelAppointment'
}

export const EDIT_APPOINTMENT = {
  query: `mutation{0}{
    editAppointment({1} {2}) {
    error errorMessage
      appointment {
        id
        status
        visitReason
        attachments {
          nodes {
            id
            file {
              url
            }
          }
        }
      }
    }
  }`,
  responseName: 'editAppointment'
}
export const GET_LIST_HEALTH_RECORD = {
  query: `{
    myHealthRecords({0}){
      edges{
        node{
          id code status patientId
          doctor { 
            name 
            photo{
              thumb large
            } 
          }
          patient {
            id
            name
          } 
          appointment {
            startTime
            date
            clinic {
              name
            }
          }
          diagnoses{
            nodes{
              content
            }
          }
        }
      }
        pageInfo {
            endCursor
            hasNextPage
            }
    }
  
  }`,
  responseName: 'myHealthRecords'
}

export const GET_CLINICS = {
  query: `{
    clinics ({0}) {
      id
      name
      address
      brandId
      brand {
        domain
      }
    }
  }`,
  responseName: 'clinics'
}

export const GET_DOCTOR_INFO = {
  query: `{
    doctor ({0}) {
      name id nextAvailableTime reviewAvg serviceTotalPrice degreeName subFee bio 
      photo{
        thumb large
      }
      doctorInfo{
        specialityContent
        doHomeService
      }
      datetimeBooked {
        nodes {
          startTime date limitTime doHomeService serviceId totalBooked
        }
      }
      brand { id name }  
      brands {
        nodes {
          id
          name
          clinics {
            nodes {
              id
              name
            }
          }
        }
      }
      userEducations{
        nodes{
          course university startYear endYear
        }
      }
      workHoursOffline{
        nodes {
        morningWorkFrom
        morningWorkTo
        morningActive
        afternoonWorkFrom
        afternoonWorkTo
        afternoonActive
        eveningWorkFrom
        eveningWorkTo
        eveningActive
        date
        scheduleType
        weekDay
        brandName
        brandId
      }}
      dayOffOffline{
        nodes {
        morningWorkFrom
        morningWorkTo
        morningActive
        afternoonWorkFrom
        afternoonWorkTo
        afternoonActive
        eveningWorkFrom
        eveningWorkTo
        eveningActive
        date
        scheduleType
        weekDay
        brandName
        brandId
      }}
      workHoursOnline {
        nodes {
        morningWorkFrom
        morningWorkTo
        morningActive
        afternoonWorkFrom
        afternoonWorkTo
        afternoonActive
        eveningWorkFrom
        eveningWorkTo
        eveningActive
        date
        scheduleType
        weekDay
        brandName
        brandId
      }}
      dayOffOnline {
        nodes {
        morningWorkFrom
        morningWorkTo
        morningActive
        afternoonWorkFrom
        afternoonWorkTo
        afternoonActive
        eveningWorkFrom
        eveningWorkTo
        eveningActive
        date
        scheduleType
        weekDay
        brandName
        brandId
      }
      }
      userJobs{
        nodes{
          workplace
          job start end now
        }
      }
      certificates{
        nodes{
          id
          name
          dateOfIssue
          placeOfIssue
        }
      }
    }
  }`,
  responseName: 'doctor'
}

export const ADD_APPOINTMENT_GROUP = {
  // sample arguments
  // mutation{addAppointmentGroup(
  // appointmentsAttributes: [
  //   {doctorId: 1246, startTime: "9:15", date: "2/12/2021", examination: "offline", doHomeService: true, visitReason: "", serviceId: 120, customerPhone: "0979598755", customerName: "Nguyen Hoai Nam 11", customerDob: "25/11/2019", customerGender: "female", examineAddress: "", patientId: 994 }
  // ],
  // appointmentGroupData: {seller: "", assigneeId: null, leadName: "Temp", source: "", sourceLevel1: "", sourceLevel2: ""}
  // )}
  query: `mutation{
    addAppointmentGroup({0}) {
      error
      errorMessage
      appointmentGroup {
        id
        leadName
      }
    }
  }`,
  responseName: 'addAppointmentGroup'
}