const COLOR = {
    bg_gray: 'var(--bg-gray)', //'#FAFAFA',
    danger: 'var(--danger)', //'#E2857F',
    text: 'var(--text)',

    clinic_dark:'var(--clinic-dark)',
    clinic_d3:'var(--clinic-d3)',
    clinic_d2:'var(--clinic-d2)',
    clinic_d1:'var(--clinic-d1)',
    clinic:'var(--clinic)',
    clinic_l1:'var(--clinic-l1)',
    clinic_l2:'var(--clinic-l2)',
    clinic_l3:'var(--clinic-l3)',
    clinic_l4:'var(--clinic-l4)',

    blue_d3:'var(--blue-d3)',
    blue_d2:'var(--blue-d2)',
    blue_d1:'var(--blue-d1)',
    blue:'var(--blue)',
    blue_l1:'var(--blue-l1)',
    blue_l2:'var(--blue-l2)',
    blue_l3:'var(--blue-l3)',

    yellow_d3:'var(--yellow-d3)',
    yellow_d2:'var(--yellow-d2)',
    yellow_d1:'var(--yellow-d1)',
    yellow:'var(--yellow)',
    yellow_l1:'var(--yellow-l1)',
    yellow_l2:'var(--yellow-l2)',
    yellow_l3:'var(--yellow-l3)',

    orange_d3:'var(--orange-d3)',
    orange_d2:'var(--orange-d2)',
    orange_d1:'var(--orange-d1)',
    orange:'var(--orange)',
    orange_l1:'var(--orange-l1)',
    orange_l2:'var(--orange-l2)',
    orange_l3:'var(--orange-l3)',

    dark_d3:'var(--dark-d3)',
    dark_d2:'var(--dark-d2)',
    dark_d1:'var(--dark-d1)',
    dark:'var(--dark)',
    dark_l1:'var(--dark-l1)',
    dark_l2:'var(--dark-l2)',
    dark_l3:'var(--dark-l3)',

    red_d3:'var(--red-d3)',
    red_d2:'var(--red-d2)',
    red_d1:'var(--red-d1)',
    red:'var(--red)',
    red_l1:'var(--red-l1)',
    red_l2:'var(--red-l2)',
    red_l3:'var(--red-l3)',

    green_d3:'var(--green-d3)',
    green_d2:'var(--green-d2)',
    green_d1:'var(--green-d1)',
    green:'var(--green)',
    green_l1:'var(--green-l1)',
    green_l2:'var(--green-l2)',
    green_l3:'var(--green-l3)',
}
export { COLOR }