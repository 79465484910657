import { Input, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons'
import { useContext, useState, useEffect } from 'react';
import ListDoctorComponent from './ListDoctorComponent';
import ListServiceComponent from './ListServiceComponent';

export default function AvailabelDoctorTab(props) {
    const [selectService, setSelectService] = useState(null);

    const onSelectedService = (serviceId) => {
        console.log("CHANGED SERVICE: ", serviceId)
        setSelectService(serviceId)
    }


    return (
        <div className='flex-verti center'>
            <ListServiceComponent onClickService={onSelectedService} />
            <ListDoctorComponent service={selectService} />
        </div>
    )
}