import { Space, Avatar } from 'antd'
import { COLOR } from './Color'
import { convertPrice } from './Converter'
import IC_EXPERIENCE from '../images/ic_experience.svg'
import IC_EDUCATION from '../images/ic_education.svg'
import { SafetyCertificateOutlined } from '@ant-design/icons'
import './doctor.css'
import moment from 'moment'

export default function CDoctorProfile(props) {
    const doctor = props.doctor
    return (
        <div className='flex-verti no-padding' style={{ maxWidth: '680px', zIndex:9999 }}>
            <Space>
                <Avatar src={doctor?.photo?.thumb ? doctor.photo.thumb : null} size={40} className='avatar-doctor' >
                    {doctor?.photo?.thumb ? "" : doctor?.name ? doctor.name.substring(0, 2) : "B"}
                </Avatar>
                <Space direction='vertical' align='start' size={0}>
                    <div style={{ fontSize: '10.5pt', fontWeight: 700 }}>
                        <span style={{ fontWeight: 500, fontSize: '7pt' }}>{doctor.positionStr}</span>{doctor.name}
                    </div>
                    <div style={{fontSize:'8pt', fontWeight:'600', color:COLOR.clinic_dark}}>{doctor.brand.name}</div>
                    <div style={{ marginTop: '0px', fontSize: '9pt', marginBottom: '5px', fontWeight: 400, textAlign: 'left' }}>{doctor?.doctorInfo?.specialityContent}</div>
                </Space>
            </Space>
            {/* <div className='dash' style={{ width: '100%', marginBottom: '4px' }} /> */}
            <div style={{ maxWidth: '680px' }}>
                {doctor?.bio ? <div className='bold-text' style={{ marginBottom: '5px', fontSize: '11pt', fontStyle: 'italic' }}>{doctor.bio}</div> : null}
                {
                    (doctor.userJobs && doctor.userJobs.nodes && doctor.userJobs.nodes.length > 0) ?
                        <div>
                            <div><span><img src={IC_EXPERIENCE} style={{ fill: COLOR.text }} /> &nbsp; Kinh nghiệm làm việc</span></div>
                            <ul>
                                {
                                    doctor.userJobs.nodes.map((job, key) => {
                                        return (
                                            <li className='slim-text' style={{ fontSize: '10.5pt', marginTop: '5px' }} key={key}>
                                                <Space direction='vertical' size={0}>
                                                    <div>{moment(job.start, 'YYYY-MM-DD').format('DD/MM/YYYY')} - {job.end ? moment(job.end, 'YYYY-MM-DD').format('DD/MM/YYYY') : 'Hiện tại'}</div>
                                                    <div>{job.workplace} - {job.job}</div>
                                                </Space>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        : null
                }
                {
                    (doctor.userEducations && doctor.userEducations.nodes && doctor.userEducations.nodes.length > 0) ?
                        <div>
                            <div style={{ marginBottom: '5px' }}><span><img src={IC_EDUCATION} style={{ fill: COLOR.text }} /> &nbsp; Trình độ học vấn</span></div>
                            <ul>
                                {
                                    doctor.userEducations.nodes.map((edu, key) => {
                                        return (
                                            <li className='slim-text' style={{ fontSize: '10.5pt', marginTop: '5px' }} key={key}>
                                                <Space direction='vertical' size={0}>
                                                    <div>{edu.startYear}{edu.endYear ? ` - ${edu.endYear}` : null}</div>
                                                    <div>{edu.university} - {edu.course}</div>
                                                </Space>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        : null
                }
                {
                    (doctor.certificates && doctor.certificates.nodes && doctor.certificates.nodes.length > 0) ?
                        <div>
                            <div style={{ marginBottom: '5px' }}><span><SafetyCertificateOutlined style={{ fontSize: '13pt' }} /> &nbsp; Chứng chỉ hành nghề</span></div>
                            <ul>
                                {
                                    doctor.certificates.nodes.map((cer, key) => {
                                        return (
                                            <li className='slim-text' style={{ fontSize: '10.5pt', marginTop: '5px' }} key={key}>
                                                <Space direction='vertical' size={0}>
                                                    <div>{moment(cer.dateOfIssue, 'YYYY-MM-DD').format('DD/MM/YYYY')}</div>
                                                    <div>{cer.name} - {cer.placeOfIssue}</div>
                                                </Space>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        : null
                }
            </div>
            {
                doctor?.subFee ?
                    <div className='slim-text' style={{ color: COLOR.orange, textAlign: 'center', fontSize: '10.5pt' }}>
                        * Phụ phí khám với Bác sĩ <br />
                        chuyên gia cao cấp: {convertPrice(doctor?.subFee)} VNĐ
                    </div>
                    : null
            }
        </div>
    )
}