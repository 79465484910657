import { useContext, useEffect, useState } from "react";
import { ServiceContext } from "./common/AppContext";
import CServiceItem from "./common/CServiceItems";
import { Collapse, Input, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons'
import './common/services.css'
import { getTimeAvailable, convertChar } from './common/Converter'
import ICON_EXPAND from './images/ic_expand.svg'
import './common/antd-custom.css'

const { Panel } = Collapse;
const { Option, OptGroup } = Select;

export default function ListServiceComponent(props) {
    const { listServiceOnline, listSpecialistOnline } = useContext(ServiceContext)
    const [selectedService, setSelectedService] = useState(null)

    const allService = listServiceOnline;
    const [listService, setListService] = useState(null)

    // useEffect(() => {
    //     if (listServiceOnline && Array.isArray(listServiceOnline)) {
    //         setAllServices(listServiceOnline)
    //         setListService(listServiceOnline)
    //     }
    // }, [listServiceOnline])

    const onSearch = (value) => {
        console.log("onSearch: ", value)
        if (!value) {
            setListService(null)
            return;
        }

        const searchText = convertChar(value).toLowerCase();
        const listSearch = allService.filter(el => {
            if (convertChar(el.name).toLowerCase().indexOf(searchText) > -1) {
                return true
            }
        })

        console.log("SEARCH RESULT:", listSearch)
        setListService(listSearch)
    }


    function ServiceHeader(props) {
        const { title, image } = props;
        return (
            <div className='service-group'>
                <img src={image?.thumb} className='service-thumb' />
                <span className='service-title'>{title ? title : ''}</span>
            </div>
        )
    }

    // useEffect(()=>{
    //     if(selectedService){

    //     }
    // }, [selectedService])

    const handleChange = (value) => {
        console.log(`selected ${value}`);
        props.onClickService && props.onClickService(value)
    };

    return (
        <div style={{ textAlign: 'left', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', color:'white' }}>
            {/* <Input className='service-search' prefix={<SearchOutlined />} placeholder='Tìm kiếm dịch vụ...' onChange={onSearch} /> */}
            <h4 style={{color:'white'}}>DỊCH VỤ: </h4>
            <div className='service-search' >
                <Select
                    bordered={false}
                    dropdownStyle={{ backgroundColor: '#d1f2f6', zIndex: 9998 }}
                    showSearch
                    style={{ width: '100%', textAlign: 'left', paddingLeft: '20px', marginRight: '20px' }}
                    onChange={handleChange}
                    optionFilterProp="children"
                    placeholder='Chọn dịch vụ'
                    // onSearch={onSearch}
                    filterOption={(input, option) => option.children && convertChar(option.children.toLowerCase()).includes(convertChar(input.toLowerCase()))}
                >
                    {
                        listSpecialistOnline ? listSpecialistOnline.map((item, index) => {
                            return (
                                <OptGroup label={item.contentVi} key={item.id} >
                                    {
                                        item.services ? item.services.map((iService, key) => {
                                            return (
                                                <Option value={iService.id} key={`${item.id}-${key}`}>{iService.name}</Option>
                                            )
                                        }) : null
                                    }
                                </OptGroup>
                            )
                        })
                            : null
                    }
                </Select>
            </div>
            {/* {
                (listService && Array.isArray(listService)) ?
                    listService.map((service, key) => {
                        return <CServiceItem data={service} key={key} />
                    })
                    :
                    <Collapse
                        accordion={true}
                        ghost={true}
                        bordered={false}
                        expandIcon={({ isActive }) => <img src={ICON_EXPAND} style={{ width: '15px', fill: 'white', top: 'calc(50% - 4px)', transform: `rotate(${isActive ? '0' : '180deg'})` }} />}
                        expandIconPosition='right'>
                        {
                            listSpecialistOnline ? listSpecialistOnline.map((item, key) => {
                                return (
                                    <Panel header={<ServiceHeader title={item.contentVi} image={item.image} />} showArrow={true} key={item.id} >
                                        {
                                            item.services ? item.services.map((iService, key) => <CServiceItem data={iService} key={key} onClick={() => setSelectedService(iService)} />) : null
                                        }
                                    </Panel>
                                )
                            })
                                : null
                        }
                    </Collapse>
            } */}
        </div>
    )
}