import React from 'react'
const AppContext = React.createContext('AppContext');
const UserContext = React.createContext('UserContext');
const HRContext = React.createContext('HRContext');
const ServiceContext = React.createContext('ServiceContext');
const BookingContext = React.createContext('BookingContext');
const DialogContext = React.createContext('DialogContext');
const AppointmentContext = React.createContext('AppointmentContext');
const ComboContext = React.createContext('ComboContext');

export {AppContext, UserContext, HRContext, ServiceContext, BookingContext, DialogContext, AppointmentContext, ComboContext}