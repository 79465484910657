import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import "./App.css";
import { Col, Row, Spin, Select, Button, Tabs, Space } from "antd";
import { MailOutlined, PhoneFilled } from "@ant-design/icons";
import TicketCard from "./TicketCard";
import axios from "axios";
import { useCookies } from "react-cookie";
import AvailabelDoctors from "./ListDoctorComponent";
import AvailabelDoctorTab from "./AvailabelDoctorTab";
import { ServiceContext } from "./common/AppContext";
import { useGetServices } from "./hook/ServiceHook";
import { COLOR } from "./common/Color";
import { isMobile } from "react-device-detect";
const { TabPane } = Tabs;
const { Option } = Select;

const apiUrl = "https://tech-support-api.med247.co"
// const apiUrl = "http://dev.local.ms.med247.co:1111";

function App() {
  const [loading, setLoading] = useState(true);
  const [newTicketsOrigin, setNewTicketsOrigin] = useState([]);
  const [inprogressOrigin, setInprogressOrigin] = useState([]);
  const [approvedOrigin, setApprovvedOrigin] = useState([]);
  const [rejectedOrigin, setRejectedOrigin] = useState([]);
  const [doneOrigin, setDoneOrigin] = useState([]);

  const [newTickets, setNewTickets] = useState([]);
  const [inprogress, setInprogress] = useState([]);
  const [approved, setApprovved] = useState([]);
  const [rejected, setRejected] = useState([]);
  const [done, setDone] = useState([]);

  const [newSupportTicketsOrigin, setNewSupportTicketsOrigin] = useState([]);
  const [supportInprogressOrigin, setSupportInprogressOrigin] = useState([]);
  const [supportPendingOrigin, setSupportPendingOrigin] = useState([]);
  const [supportCannotResolveOrigin, setSupportCannotResolveOrigin] = useState(
    []
  );
  const [supportDoneOrigin, setSupportDoneOrigin] = useState([]);

  const [newSupportTickets, setNewSupportTickets] = useState([]);
  const [supportInprogress, setSupportInprogress] = useState([]);
  const [supportPending, setSupportPending] = useState([]);
  const [supportCannotResolve, setSupportCannotResolve] = useState([]);
  const [supportDone, setSupportDone] = useState([]);

  const [newOiOrigin, setNewOiOrigin] = useState([]);
  const [oiInprogressOrigin, setOiInprogressOrigin] = useState([]);
  const [oiPendingOrigin, setOiPendingOrigin] = useState([]);
  const [oiCannotResolveOrigin, setOiCannotResolveOrigin] = useState([]);
  const [oiDoneOrigin, setOiDoneOrigin] = useState([]);

  const [newOi, setNewOi] = useState([]);
  const [oiInprogress, setOiInprogress] = useState([]);
  const [oiPending, setOiPending] = useState([]);
  const [oiCannotResolve, setOiCannotResolve] = useState([]);
  const [oiDone, setOiDone] = useState([]);

  const [emails, setEmails] = useState([]);

  const [selectedEmail, setSelectedEmail] = useState(null);

  const [services, setServices] = useState([]);
  const listServices = useGetServices();

  const [cookies, setCookie, removeCookie] = useCookies(["user"]);

  useEffect(() => {
    if (listServices) setServices(listServices);
  }, [listServices]);

  useEffect(() => {
    getTickets();
    getOperationIssues();
    return () => {};
  }, []);

  const getTickets = async () => {
    setLoading(true);
    const config = {
      method: "get",
      url: `${apiUrl}/`,
      // url: 'http://localhost:1111',
      mode: "no-cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    };

    try {
      const res = await axios(config);

      if (res.status === 200 && res.data) {
        console.log("RESPONSE: ", res.data);
        const allTickets = res.data;

        getTicketComments(allTickets.todo, (todoTickets) => {
          setNewTicketsOrigin(todoTickets);
          setNewTickets(todoTickets);
          console.log("SET NEW TICKET TODO: ", todoTickets);
        });
        getTicketComments(allTickets.doing, (doingTickets) => {
          setInprogressOrigin(doingTickets);
          setInprogress(doingTickets);
        });
        getTicketComments(allTickets.approved, (approveTickets) => {
          setApprovvedOrigin(approveTickets);
          setApprovved(approveTickets);
        });
        getTicketComments(allTickets.rejected, (rejectTickets) => {
          setRejectedOrigin(rejectTickets);
          setRejected(rejectTickets);
        });
        getTicketComments(allTickets.done, (doneTickets) => {
          setDoneOrigin(doneTickets);
          setDone(doneTickets);
        });

        if (allTickets.support_todo) {
          setNewSupportTicketsOrigin(allTickets.support_todo);
          setNewSupportTickets(allTickets.support_todo);
        }
        if (allTickets.support_doing) {
          setSupportInprogressOrigin(allTickets.support_doing);
          setSupportInprogress(allTickets.support_doing);
        }
        if (allTickets.support_pending) {
          setSupportPendingOrigin(allTickets.support_pending);
          setSupportPending(allTickets.support_pending);
        }
        if (allTickets.support_cannotResolve) {
          setSupportCannotResolveOrigin(allTickets.support_cannotResolve);
          setSupportCannotResolve(allTickets.support_cannotResolve);
        }
        if (allTickets.support_done) {
          setSupportDoneOrigin(allTickets.support_done);
          setSupportDone(allTickets.support_done);
        }

        const otherEmail = await getOperationIssues();

        setEmails([...allTickets.email, ...otherEmail]);
      }
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const getOperationIssues = async () => {
    setLoading(true);
    const config = {
      method: "get",
      url: `${apiUrl}/operationissue`,
      // url: 'http://localhost:1111/operationissue',
      mode: "no-cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    };

    try {
      let res = await axios(config);

      if (res.status === 200 && res.data) {
        console.log("RESPONSE: ", res.data);
        const allTickets = res.data;

        if (allTickets.support_todo) {
          setNewOiOrigin(allTickets.support_todo);
          setNewOi(allTickets.support_todo);
        }
        if (allTickets.support_doing) {
          setOiInprogressOrigin(allTickets.support_doing);
          setOiInprogress(allTickets.support_doing);
        }
        if (allTickets.support_pending) {
          setOiPendingOrigin(allTickets.support_pending);
          setOiPending(allTickets.support_pending);
        }
        if (allTickets.support_cannotResolve) {
          setOiCannotResolveOrigin(allTickets.support_cannotResolve);
          setOiCannotResolve(allTickets.support_cannotResolve);
        }
        if (allTickets.support_done) {
          setOiDoneOrigin(allTickets.support_done);
          setOiDone(allTickets.support_done);
        }

        setLoading(false);
        return allTickets.email;
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
    return [];
  };

  const getTicketComments = async (tickets, callback) => {
    let listRequest = [];
    let ticketWithComment = [];
    tickets.forEach((ticket) => {
      listRequest.push(getCommentByTicketId(ticket.key));
    });
    Promise.all(listRequest).then((values) => {
      values.forEach((comments) => {
        // console.log("comments: ", comments)
        const ticket = tickets.find((t) => t.key === comments?.tid);

        // console.log("ticket found: ", ticket)
        if (ticket)
          ticketWithComment.push({ ...ticket, comments: comments.comments });
      });
      // console.log("tickets: ", tickets)
      // console.log("ticketWithComment: ", ticketWithComment)
      callback && callback(ticketWithComment);
    });
    // callback(ticketWithComment)
  };

  const getCommentByTicketId = async (tid) => {
    const config = {
      method: "get",
      url: `${apiUrl}/comments?tid=${tid}`,
      // url: `http://localhost:1111/comments?tid=${tid}`,
      mode: "no-cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    };

    let commentRes = await axios(config);
    try {
      if (commentRes && commentRes.data) {
        // console.log(commentRes.data)
        return commentRes.data;
      }
      // axios(config).then(function (res) {

      //   if (res.status === 200 && res.data) {
      //     console.log("RESPONSE: ", res.data)
      //     const allTickets = res.data

      //     setEmails(allTickets.email)

      //   }
      //   setLoading(false)
      // })
    } catch (err) {
      console.error(err);
      setLoading(false);
      return null;
    }
  };

  const onChange = (value) => {
    if (value !== "all") {
      setSelectedEmail(value);
      console.log("SELECTED EMAIL: ", value);
      setNewTickets(
        newTicketsOrigin.filter(
          (t) => t.fields.description && t.fields.description.includes(value)
        )
      );
      setInprogress(
        inprogressOrigin.filter(
          (t) => t.fields.description && t.fields.description.includes(value)
        )
      );
      setApprovved(
        approvedOrigin.filter(
          (t) => t.fields.description && t.fields.description.includes(value)
        )
      );
      setRejected(
        rejectedOrigin.filter(
          (t) => t.fields.description && t.fields.description.includes(value)
        )
      );
      setDone(
        doneOrigin.filter(
          (t) => t.fields.description && t.fields.description.includes(value)
        )
      );

      setNewSupportTickets(
        newSupportTicketsOrigin.filter(
          (t) => t.fields.description && t.fields.description.includes(value)
        )
      );
      setSupportInprogress(
        supportInprogressOrigin.filter(
          (t) => t.fields.description && t.fields.description.includes(value)
        )
      );
      setSupportPending(
        supportPendingOrigin.filter(
          (t) => t.fields.description && t.fields.description.includes(value)
        )
      );
      setSupportCannotResolve(
        supportCannotResolveOrigin.filter(
          (t) => t.fields.description && t.fields.description.includes(value)
        )
      );
      setSupportDone(
        supportDoneOrigin.filter(
          (t) => t.fields.description && t.fields.description.includes(value)
        )
      );
    } else {
      setSelectedEmail(null);

      setNewTickets(newTicketsOrigin);
      setInprogress(inprogressOrigin);
      setApprovved(approvedOrigin);
      setRejected(rejectedOrigin);
      setDone(doneOrigin);

      setNewSupportTickets(newSupportTicketsOrigin);
      setSupportInprogress(supportInprogressOrigin);
      setSupportPending(supportPendingOrigin);
      setSupportCannotResolve(supportCannotResolveOrigin);
      setSupportDone(supportDoneOrigin);
    }
  };

  const onSearch = (value) => {
    console.log("search:", value);
  };

  const filterComponent = (
    <Select
      style={{ width: "350px", textAlign: "left", marginBottom: "15px" }}
      showSearch
      placeholder="Lọc theo email"
      optionFilterProp="children"
      onChange={onChange}
      onSearch={onSearch}
      filterOption={(input, option) =>
        option.children.toLowerCase().includes(input.toLowerCase())
      }
    >
      <Option value="all" key="all">
        Tất cả
      </Option>
      {emails.map((email, key) => (
        <Option value={email} key={key}>
          {email}
        </Option>
      ))}
    </Select>
  );

  return (
    <div className="App">
      {loading ? (
        <Spin />
      ) : (
        <div>
          <Row>
            <Col style={{ marginLeft: "20px" }}></Col>
          </Row>
          <div className="card-container" style={{ margin: "10px" }}>
            {filterComponent}
            <Tabs
              defaultActiveKey="1"
              type="card"
              centered
              tabBarExtraContent={null}
            >
              <TabPane
                tab={`${
                  isMobile ? "Hỗ trợ" : "⚙️ Tình trạng trợ giúp kỹ thuật"
                }`}
                key="1"
              >
                <Row wrap>
                  <Col xs={24} md={8}>
                    <div className="flex-verti board">
                      <div
                        style={{
                          marginTop: "5px",
                          marginBottom: "5px",
                          fontWeight: "bold",
                          textAlign: "left",
                          marginLeft: "10px",
                          color: "white",
                        }}
                      >
                        CHỜ HỖ TRỢ:
                      </div>
                      {newSupportTickets.sort((a,b)=>new Date(b.fields.created)-new Date(a.fields.created)).map((ticket, key) => (
                        <TicketCard key={key} data={ticket} />
                      ))}
                    </div>
                  </Col>
                  <Col xs={24} md={8}>
                    <div className="flex-verti board">
                      <div
                        style={{
                          marginTop: "5px",
                          marginBottom: "5px",
                          fontWeight: "bold",
                          textAlign: "left",
                          marginLeft: "10px",
                          color: "white",
                        }}
                      >
                        ĐANG XỬ LÝ:
                      </div>
                      {supportInprogress.sort((a,b)=>new Date(b.fields.created)-new Date(a.fields.created)).map((ticket, key) => (
                        <TicketCard key={key} data={ticket} />
                      ))}
                    </div>
                  </Col>
                  <Col xs={24} md={8}>
                    <div className="flex-verti board">
                      <div
                        style={{
                          marginTop: "5px",
                          marginBottom: "5px",
                          fontWeight: "bold",
                          textAlign: "left",
                          color: "white",
                        }}
                      >
                        TẠM HOÃN:
                      </div>
                      {supportPending.sort((a,b)=>new Date(b.fields.created)-new Date(a.fields.created)).map((ticket, key) => (
                        <TicketCard key={key} data={ticket} />
                      ))}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <div className="flex-verti board">
                      <div
                        style={{
                          marginTop: "5px",
                          marginBottom: "5px",
                          fontWeight: "bold",
                          textAlign: "left",
                          color: "red",
                          marginLeft: "10px",
                        }}
                      >
                        KHÔNG THỂ HỖ TRỢ:
                      </div>
                      {supportCannotResolve.sort((a,b)=>new Date(b.fields.created)-new Date(a.fields.created)).map((ticket, key) => (
                        <TicketCard key={key} data={ticket} color="orange" />
                      ))}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="flex-verti board">
                      <div
                        style={{
                          marginTop: "5px",
                          marginBottom: "5px",
                          fontWeight: "bold",
                          textAlign: "left",
                          marginLeft: "10px",
                          color: "lightgreen",
                        }}
                      >
                        ĐÃ GIẢI QUYẾT:
                      </div>
                      {supportDone.sort((a,b)=>new Date(b.fields.created)-new Date(a.fields.created)).map((ticket, key) => (
                        <TicketCard
                          key={key}
                          data={ticket}
                          color="lightgreen"
                        />
                      ))}
                    </div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane
                tab={`${isMobile ? "Góp Ý" : "💡 Tình trạng xử lý góp ý"}`}
                key="2"
                style={{ backgroundColor: "white" }}
              >
                <Row wrap>
                  <Col xs={24} md={8}>
                    <div className="flex-verti board">
                      <div
                        style={{
                          marginTop: "5px",
                          marginBottom: "10px",
                          fontWeight: "bold",
                          textAlign: "left",
                          marginLeft: "10px",
                          color: "white",
                        }}
                      >
                        Đề xuất chờ xử lý:
                      </div>
                      {newTickets.sort((a,b)=>new Date(b.fields.created)-new Date(a.fields.created)).map((ticket, key) => (
                        <TicketCard key={key} data={ticket} />
                      ))}
                    </div>
                  </Col>
                  <Col xs={24} md={8}>
                    <div className="flex-verti board">
                      <div
                        style={{
                          marginTop: "5px",
                          marginBottom: "10px",
                          fontWeight: "bold",
                          textAlign: "left",
                          marginLeft: "10px",
                          color: "white",
                        }}
                      >
                        Đang đánh giá và phân tích:
                      </div>
                      {inprogress.sort((a,b)=>new Date(b.fields.created)-new Date(a.fields.created)).map((ticket, key) => (
                        <TicketCard key={key} data={ticket} />
                      ))}
                    </div>
                  </Col>
                  <Col xs={24} md={8}>
                    <div className="flex-verti board">
                      <div
                        style={{
                          marginTop: "5px",
                          marginBottom: "10px",
                          fontWeight: "bold",
                          textAlign: "left",
                          color: "white",
                        }}
                      >
                        Đang thực hiện cải tiến:
                      </div>
                      {approved.sort((a,b)=>new Date(b.fields.created)-new Date(a.fields.created)).map((ticket, key) => (
                        <TicketCard key={key} data={ticket} />
                      ))}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <div className="flex-verti board">
                      <div
                        style={{
                          marginTop: "5px",
                          marginBottom: "10px",
                          fontWeight: "bold",
                          textAlign: "left",
                          color: "red",
                          marginLeft: "10px",
                        }}
                      >
                        Danh sách yêu cầu bị từ chối:
                      </div>
                      {rejected.sort((a,b)=>new Date(b.fields.created)-new Date(a.fields.created)).map((ticket, key) => (
                        <TicketCard key={key} data={ticket} color="orange" />
                      ))}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="flex-verti board">
                      <div
                        style={{
                          marginTop: "5px",
                          marginBottom: "10px",
                          fontWeight: "bold",
                          textAlign: "left",
                          marginLeft: "10px",
                          color: "green",
                        }}
                      >
                        Đã hoàn thành:
                      </div>
                      {done.sort((a,b)=>new Date(b.fields.created)-new Date(a.fields.created)).map((ticket, key) => (
                        <TicketCard
                          key={key}
                          data={ticket}
                          color="lightgreen"
                        />
                      ))}
                    </div>
                  </Col>
                </Row>
              </TabPane>

              <TabPane
                tab={`${isMobile ? "Vận hành" : "Vướng mắc vận hành"}`}
                key="3"
              >
                <Row wrap>
                  <Col xs={24} md={8}>
                    <div className="flex-verti board">
                      <div
                        style={{
                          marginTop: "5px",
                          marginBottom: "5px",
                          fontWeight: "bold",
                          textAlign: "left",
                          marginLeft: "10px",
                          color: "white",
                        }}
                      >
                        CHỜ HỖ TRỢ:
                      </div>
                      {newOi.sort((a,b)=>new Date(b.fields.created)-new Date(a.fields.created)).map((ticket, key) => (
                        <TicketCard key={key} data={ticket} />
                      ))}
                    </div>
                  </Col>
                  <Col xs={24} md={8}>
                    <div className="flex-verti board">
                      <div
                        style={{
                          marginTop: "5px",
                          marginBottom: "5px",
                          fontWeight: "bold",
                          textAlign: "left",
                          marginLeft: "10px",
                          color: "white",
                        }}
                      >
                        ĐANG XỬ LÝ:
                      </div>
                      {oiInprogress.sort((a,b)=>new Date(b.fields.created)-new Date(a.fields.created)).map((ticket, key) => (
                        <TicketCard key={key} data={ticket} />
                      ))}
                    </div>
                  </Col>
                  <Col xs={24} md={8}>
                    <div className="flex-verti board">
                      <div
                        style={{
                          marginTop: "5px",
                          marginBottom: "5px",
                          fontWeight: "bold",
                          textAlign: "left",
                          color: "white",
                        }}
                      >
                        TẠM HOÃN:
                      </div>
                      {oiPending.sort((a,b)=>new Date(b.fields.created)-new Date(a.fields.created)).map((ticket, key) => (
                        <TicketCard key={key} data={ticket} />
                      ))}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <div className="flex-verti board">
                      <div
                        style={{
                          marginTop: "5px",
                          marginBottom: "5px",
                          fontWeight: "bold",
                          textAlign: "left",
                          color: "red",
                          marginLeft: "10px",
                        }}
                      >
                        KHÔNG THỂ HỖ TRỢ:
                      </div>
                      {oiCannotResolve.sort((a,b)=>new Date(b.fields.created)-new Date(a.fields.created)).map((ticket, key) => (
                        <TicketCard key={key} data={ticket} color="orange" />
                      ))}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="flex-verti board">
                      <div
                        style={{
                          marginTop: "5px",
                          marginBottom: "5px",
                          fontWeight: "bold",
                          textAlign: "left",
                          marginLeft: "10px",
                          color: "lightgreen",
                        }}
                      >
                        ĐÃ GIẢI QUYẾT:
                      </div>
                      {oiDone.sort((a,b)=>new Date(b.fields.created)-new Date(a.fields.created)).map((ticket, key) => (
                        <TicketCard
                          key={key}
                          data={ticket}
                          color="lightgreen"
                        />
                      ))}
                    </div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab={`${isMobile ? "Bác sĩ" : "Bác sĩ trống"}`} key="4">
                <Row>
                  <Col span={24}>
                    <ServiceContext.Provider value={services}>
                      <AvailabelDoctorTab />
                    </ServiceContext.Provider>
                  </Col>
                </Row>
              </TabPane>
            </Tabs>
          </div>
        </div>
      )}
      <div className="footer">
        <Row style={{ backgroundColor: "black" }} wrap>
          <Col
            xs={24}
            md={10}
            style={{
              backgroundColor: "black",
              padding: "20px",
              paddingBottom: "40px",
            }}
          >
            <Space direction="vertical" align="start">
              <h2
                style={{
                  color: "white",
                  textAlign: "left",
                  marginBottom: "20px",
                }}
              >
                PHÒNG NGHIÊN CỨU VÀ PHÁT TRIỂN CÔNG NGHỆ MED247
              </h2>
              <div
                style={{
                  textAlign: "left",
                  color: "#616d7e",
                  marginBottom: "14px",
                }}
              >
                Ngoài việc phát triển những tính năng phục vụ vận hành và tăng
                trải nghiệm cho khách hàng. Tech Team vẫn đang nỗ lực nghiên cứu
                và phát triển những công nghệ hiện đại để có thể tạo nên bước
                ngoặt về công nghệ trong y tế của Việt Nam.
                <br />
                Smart Device, IOT, AI, Cá nhân hoá người dùng chính là chặng
                đường dài đầy thử thách mà Tech Team đang hướng tới.
              </div>
              <span>
                <MailOutlined style={{ fontSize: "16px" }} /> &nbsp;&nbsp;&nbsp;
                <a href="mailto:tech@med247.co" className="link-underlined">
                  tech@med247.co
                </a>
              </span>
              <span>
                <PhoneFilled style={{ fontSize: "16px" }} /> &nbsp;&nbsp;&nbsp;
                <a
                  href="tel:+84979598755"
                  style={{ fontWeight: "bold", fontSize: "14pt" }}
                >
                  0979.598.755
                </a>
              </span>
            </Space>
          </Col>
          <Col
            sm={0}
            md={2}
            style={{ backgroundColor: "black", padding: "20px" }}
          ></Col>
          <Col
            xs={24}
            md={5}
            style={{
              backgroundColor: "black",
              padding: "20px",
              paddingBottom: "40px",
            }}
          >
            <div className="block-footer">
              <div className="heading-footer">Truy cập nhanh</div>
              <a
                target="_blank"
                href="https://confluence.med247.co/display/PPK/Suggestion+Handling+Process"
                className="link-footer"
              >
                Quy trình xử lý góp ý
              </a>
              <a
                target="_blank"
                href="https://confluence.med247.co/display/PPK/Med247%27s+Products"
                className="link-footer"
              >
                Các dự án công nghệ
              </a>
              <a
                target="_blank"
                href="https://confluence.med247.co/pages/viewpage.action?pageId=79138191"
                className="link-footer"
              >
                Hướng dẫn sử dụng
              </a>
              <a
                target="_blank"
                href="https://confluence.med247.co/display/PPK/Retrospectives"
                className="link-footer"
              >
                Báo cáo
              </a>
              <a
                target="_blank"
                href="https://confluence.med247.co/pages/viewpage.action?pageId=34832873"
                className="link-footer"
              >
                Tech Road Map
              </a>
            </div>
          </Col>
          <Col
            xs={24}
            md={7}
            style={{
              backgroundColor: "black",
              padding: "20px",
              paddingBottom: "40px",
            }}
          >
            <div className="block-footer">
              <div className="heading-footer">Liên Hệ Kỹ Thuật</div>
              <Row style={{ width: "100%", marginBottom: "15px" }}>
                <Col
                  span={9}
                  style={{
                    backgroundColor: "black",
                    textAlign: "left",
                    fontWeight: 500,
                    color: "#616d7e",
                  }}
                >
                  Lỗi hệ thống CMS:
                </Col>
                <Col
                  span={15}
                  style={{
                    backgroundColor: "black",
                    textAlign: "left",
                    fontWeight: 500,
                    color: "#616d7e",
                  }}
                >
                  Thái - <a href="tel:+84979598755">0979.598.755</a> <br />{" "}
                  Phương Nam - <a href="tel:+84979598755">0979.598.755</a>
                </Col>
              </Row>
              <Row style={{ width: "100%", marginBottom: "15px" }}>
                <Col
                  span={9}
                  style={{
                    backgroundColor: "black",
                    textAlign: "left",
                    fontWeight: 500,
                    color: "#616d7e",
                  }}
                >
                  Số liệu - báo cáo:
                </Col>
                <Col
                  span={15}
                  style={{
                    backgroundColor: "black",
                    textAlign: "left",
                    fontWeight: 500,
                    color: "#616d7e",
                  }}
                >
                  Huy BI - <a href="tel:+84979598755">0979.598.755</a>
                </Col>
              </Row>
              <Row style={{ width: "100%", marginBottom: "15px" }}>
                <Col
                  span={9}
                  style={{
                    backgroundColor: "black",
                    textAlign: "left",
                    fontWeight: 500,
                    color: "#616d7e",
                  }}
                >
                  Trục trặc máy móc:
                </Col>
                <Col
                  span={15}
                  style={{
                    backgroundColor: "black",
                    textAlign: "left",
                    fontWeight: 500,
                    color: "#616d7e",
                  }}
                >
                  Long - <a href="tel:+84979598755">0979.598.755</a>
                </Col>
              </Row>
              <Row style={{ width: "100%", marginBottom: "15px" }}>
                <Col
                  span={9}
                  style={{
                    backgroundColor: "black",
                    textAlign: "left",
                    fontWeight: 500,
                    color: "#616d7e",
                  }}
                >
                  Cải tiến tính năng:
                </Col>
                <Col
                  span={15}
                  style={{
                    backgroundColor: "black",
                    textAlign: "left",
                    fontWeight: 500,
                    color: "#616d7e",
                  }}
                >
                  Sơn PO - <a href="tel:+84979598755">0979.598.755</a>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      <div className="med247footer">Copyright © 2022 Med247 JSC</div>
    </div>
  );
}

export default App;
