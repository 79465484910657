import { Badge, Button, Collapse, Comment, Space } from "antd";
import Tooltip from "antd/es/tooltip";
import React, { useEffect, useState } from "react";
import moment from "moment";
import Input from "antd/lib/input/Input";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";

import { useCookies } from "react-cookie";
// import {HtmlToReact} from 'html-to-react'

const { Panel } = Collapse;

export default function TicketCard({ data, color }) {
  // const reactElement = HtmlToReact.Parse.parse(data.fields.description);
  const [author, setAuthor] = useState(null);
  const [newComment, setNewComment] = useState(null);
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState([]);

  const [cookies, setCookie, removeCookie] = useCookies(["user"]);

  useEffect(() => {
    if (cookies && cookies.user) {
      setAuthor(cookies.user);
    }
  }, [cookies]);

  useEffect(() => {
    if (data.comments) {
      setComments(data.comments);
    }
  }, [data]);

  const sendComment = async () => {
    setLoading(true);
    setCookie("user", author);
    let finalComment = `*${author}:* ${newComment}`;
    const config = {
      method: "post",
      url: `https://tech-support-api.med247.co/comment?ticket=${data.key}&content=${finalComment}`,
      //   url: `http://localhost:1111/comment?ticket=${data.key}&content=${finalComment}`,
      mode: "no-cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    };

    try {
      axios(config)
        .then(function (res) {
          if (res.status === 200 && res.data && res.data.success) {
            console.log("RESPONSE: ", res.data);
            setNewComment("");
            const responseData = res.data;
            let latestComments = [...comments];
            latestComments.push(responseData.data);
            setComments(latestComments);
          }
          setLoading(false);
        })
        .catch(function (error) {
          console.log(error);
          setLoading(false);
        });
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  return (
    <Badge count={comments ? comments.length : 0} offset={[-10, 14]}>
      <div style={{ margin: "5px", borderRadius: "8px", overflow: "hidden" }}>
        <Collapse
          defaultActiveKey={["1"]}
          style={{
            backgroundColor: color ? `${color} !important` : "transparent",
            borderRadius: "8px",
          }}
        >
          <Panel
            header={
              <div
                className="title-ticket"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Tooltip title={data.fields.summary}>
                  <div className="title-ticket">{data.fields.summary}</div>
                </Tooltip>
                <Tooltip title={data.fields.assignee?.displayName}>
                  <img src={data.fields.assignee?.avatarUrls["24x24"]} />
                </Tooltip>
              </div>
            }
          >
            <div style={{ textAlign: "left", marginBottom: "5px" }}>
              <b>
                ID:{" "}
                <a
                  target="_blank"
                  href={`https://jira.med247.co/browse/${data.key}`}
                >
                  {data ? data.key : ""}
                </a>
              </b>
            </div>
            <div style={{ textAlign: "left" }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: data?.renderedFields?.description||data?.fields?.description,
                }}
              />
            </div>
            <div
              style={{
                textAlign: "right",
                fontSize: "10pt",
                color: "gray",
                fontStyle: "italic",
              }}
            >
              {moment(data.fields.created).format("DD/MM/YYYY")}
            </div>
            {comments && comments.length > 0
              ? comments.map((comment, key) => {
                  if (
                    comment.author.key === "JIRAUSER12804" &&
                    comment.body.split("*").length === 3
                  )
                    return (
                      <Comment
                        key={key}
                        author={null}
                        avatar={
                          <img src={comment.author.avatarUrls["24x24"]} />
                        }
                        content={
                          <div style={{ textAlign: "left" }}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: comment.renderedBody,
                              }}
                            />
                          </div>
                        }
                        datetime={
                          <Tooltip
                            title={moment(comment.updated).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )}
                          >
                            <span>{moment(comment.updated).fromNow()}</span>
                          </Tooltip>
                        }
                      />
                    );
                  else
                    return (
                      <Comment
                        key={key}
                        author={<a>{comment.author.displayName}</a>}
                        avatar={
                          <img src={comment.author.avatarUrls["24x24"]} />
                        }
                        content={
                          <div style={{ textAlign: "left" }}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: comment.renderedBody,
                              }}
                            />
                          </div>
                        }
                        datetime={
                          <Tooltip
                            title={moment(comment.updated).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )}
                          >
                            <span>{moment(comment.updated).fromNow()}</span>
                          </Tooltip>
                        }
                      />
                    );
                })
              : null}
            <TextArea
              style={{ marginTop: "10px" }}
              onChange={(e) => setNewComment(e.target.value)}
              value={newComment}
              placeholder="Hãy cho chúng tôi biết ý kiến của bạn"
            ></TextArea>
            <div style={{ display: "flex", marginTop: "5px" }}>
              <Input
                placeholder="Tên người gửi - Bộ phận"
                onChange={(e) => setAuthor(e.target.value)}
                value={author}
              ></Input>
              <Button type="primary" onClick={sendComment} disabled={loading}>
                Gửi
              </Button>
            </div>
          </Panel>
        </Collapse>
      </div>
    </Badge>
  );
}
