import moment from "moment";

export const getDateTime = (timestamp) => {
    return moment.unix(timestamp).format('DD/MM/YYYY H:mm:ss');
}

export const getDate = (timestamp) => {
    return moment.unix(timestamp).format('DD/MM/YYYY');
}

export const getTime = (timestamp) => {
    return moment.unix(timestamp).format('H:mm:ss');
}
export const sameDay = (d1, d2) => {
    return d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate();
}

export const convertPrice = (price) => {
    if (price == null) return '0 '
    else
        return new Intl.NumberFormat().format(price);
  }
  
export const convertChar = (str) => {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    return str;
}

export const getWeekDayString = (weekdayIndex) => {
    switch (weekdayIndex) {
        case 0:
            return "Sunday";
        case 1:
            return "Monday";
        case 2:
            return "Tuesday";
        case 3:
            return "Wednesday";
        case 4:
            return "Thursday";
        case 5:
            return "Friday";
        case 6:
            return "Saturday";
        default:
            return "Monday";
    }
};

export const minutesOfDay = function (m) {
    return m.minutes() + m.hours() * 60;
};

export const getAvailablePointTime = (workMetrix, listWorkOff, day) => {
    return workMetrix;
};

export const getTimeAvailable = (
    doctor,
    listDays,
    isOnline,
    timeStep,
    brandId
) => {
    let fullWorkHours = [];

    const workConfig = isOnline
        ? doctor.workHoursOnline.nodes
        : doctor.workHoursOffline.nodes;
    const offConfig = isOnline
        ? doctor.dayOffOnline.nodes
        : doctor.dayOffOffline.nodes;
    // console.log("---- Work Config: ", workConfig);
    let lastAvailabel = 0;
    let firstAvailable = (24 * 60) / timeStep;
    // console.log("---- Step", firstAvailable);

    for (let index = 0; index < listDays.length; index++) {
        // for (let index = 0; index < 1; index++) {
        const day = listDays[index];
        const workTime = getWorkInDay(
            workConfig,
            offConfig,
            doctor.datetimeBooked.nodes,
            day,
            timeStep,
            brandId,
            isOnline
        );
        if (workTime.minTimeIndex < firstAvailable)
            firstAvailable = workTime.minTimeIndex;
        if (workTime.maxTimeIndex > lastAvailabel)
            lastAvailabel = workTime.maxTimeIndex;

        fullWorkHours.push(workTime.workhour);
    }

    // console.log("WORK HOUR: ", fullWorkHours);
    // console.log("---- FRIST: ", firstAvailable);
    // console.log("---- LAST: ", lastAvailabel);
    // console.log("---- ALL WORK DATA: ", fullWorkHours)

    // change to use array
    let simpleWorkHours = [];
    for (let i = 0; i < fullWorkHours.length; i++) {
        let dayWork = fullWorkHours[i];
        dayWork = dayWork.slice(firstAvailable + 1, lastAvailabel + 1);
        // dayWork = dayWork.slice(0, firstAvailable);
        simpleWorkHours[i] = dayWork;
    }

    return simpleWorkHours;
};

export const isSameTimeInDay = (time1, time2) => {
    return (
        time1 &&
        time2 &&
        time1.isSame(time2, "hour") &&
        time1.isSame(time2, "minute")
    );
};

/**
 * Get work times in a day
 * @param {Array} listWorkHour - Khung giờ làm việc.
 * @param {Array} listWorkOff - Thời gian nghỉ, không làm việc
 * @param {Array} booked - Những khung giờ mà khách hàng khác đã book trước đó.
 * @param {moment} day - Ngày đem tính toán
 * @param {Integer} timeStep - Những khung giờ mà khách hàng khác đã book trước đó.
 * @param {String} brandId - Band của bác sĩ.
 */
export const getWorkInDay = (
    listWorkHour,
    listWorkOff,
    booked,
    day,
    timeStep,
    brandId,
    isOnline
) => {
    let workhour = [];
    let currentTime = moment();
    let time = moment().startOf("day");

    //Lay ra dinh dang string cua ngay dem tra cuu
    const dayWeek = getWeekDayString(day.day());

    //Tim dung config cua ngay hom do trong tuan
    let foundDayConfig = false;
    let daysFound = listWorkHour.filter((t) => {
        return (
            (t.weekDay === dayWeek || t.date === moment(day).format("YYYY-MM-DD")) &&
            t.brandId === (brandId ? brandId : t.brandId)
        );
    });
    if (daysFound) {
        foundDayConfig = true;
        if (!Array.isArray(daysFound)) daysFound = [daysFound];
    }

    //Doi chieu xem co ngay nghi dot xuat khong
    const currentDateStr = day.format("YYYY-MM-DD");
    const dayOff = listWorkOff.find((t) => t.date === currentDateStr);

    //Tinh tong so moc thoi gian trong 1 ngay
    const totalLoop = (24 * 60) / timeStep;

    let minTimeIndex = 0;
    let maxTimeIndex = 0;
    let foundAvailabelTime = false;
    //Tao ra cac moc thoi gian voi trang thai lam viec cua bac si
    for (var i = 0; i < totalLoop; i++) {
        let totalBooked = 0;
        let isWork = false; //Mặc định mốc thời gian này là ko làm việc
        let totalMinute = minutesOfDay(time);
        const isNotPass =
            day.isSame(currentTime, "day") === false ||
            (day.isSame(currentTime, "day") &&
                totalMinute > minutesOfDay(currentTime) + 10);

        //Neu tim thay cau hinh lich lam viec cua ngay hom do, so sánh với mốc hiện tại
        if (foundDayConfig && isNotPass) {
            daysFound.forEach((item) => {
                //Collect Config work hour info
                const morningFrom = minutesOfDay(
                    moment(item.morningWorkFrom, "hh:mm:ss")
                );
                const morningTo = minutesOfDay(moment(item.morningWorkTo, "hh:mm:ss"));
                const afternoonFrom = minutesOfDay(
                    moment(item.afternoonWorkFrom, "hh:mm:ss")
                );
                const afternoonTo = minutesOfDay(
                    moment(item.afternoonWorkTo, "hh:mm:ss")
                );
                const eveningFrom = minutesOfDay(
                    moment(item.eveningWorkFrom, "hh:mm:ss")
                );
                const eveningTo = minutesOfDay(moment(item.eveningWorkTo, "hh:mm:ss"));

                if (
                    (item.morningActive &&
                        morningFrom <= totalMinute &&
                        totalMinute < morningTo) ||
                    (item.afternoonActive &&
                        afternoonFrom <= totalMinute &&
                        totalMinute < afternoonTo) ||
                    (item.eveningActive &&
                        eveningFrom <= totalMinute &&
                        totalMinute < eveningTo)
                )
                    isWork = true;

                //Collect Config day Off info
                if (isWork && dayOff) {
                    const offMorningFrom = minutesOfDay(
                        moment(dayOff.morningWorkFrom, "hh:mm:ss")
                    );
                    const offMorningTo = minutesOfDay(
                        moment(dayOff.morningWorkTo, "hh:mm:ss")
                    );
                    const offAfternoonFrom = minutesOfDay(
                        moment(dayOff.afternoonWorkFrom, "hh:mm:ss")
                    );
                    const offAfternoonTo = minutesOfDay(
                        moment(dayOff.afternoonWorkTo, "hh:mm:ss")
                    );
                    const offEveningFrom = minutesOfDay(
                        moment(dayOff.eveningWorkFrom, "hh:mm:ss")
                    );
                    const offEveningTo = minutesOfDay(
                        moment(dayOff.eveningWorkTo, "hh:mm:ss")
                    );

                    if (
                        (dayOff.morningActive &&
                            offMorningFrom <= totalMinute &&
                            totalMinute < offMorningTo) ||
                        (dayOff.afternoonActive &&
                            offAfternoonFrom <= totalMinute &&
                            totalMinute < offAfternoonTo) ||
                        (dayOff.eveningActive &&
                            offEveningFrom <= totalMinute &&
                            totalMinute < offEveningTo)
                    )
                        isWork = false;
                }

                if (isWork) {
                    booked.forEach((item) => {
                        const dateBooked = moment(item.date, "YYYY-MM-DD");
                        const bookedTime = minutesOfDay(moment.parseZone(item.startTime));
                        const endTime = bookedTime + item.limitTime;
                        if (
                            dateBooked.isSame(day, "day") &&
                            ((totalMinute <= bookedTime &&
                                bookedTime < totalMinute + timeStep) ||
                                (totalMinute < endTime && endTime <= totalMinute + timeStep))
                        ) {
                            totalBooked = totalBooked + 1;
                            // if (dateBooked.format("DD") === "22")
                            //   console.log(
                            //     "BOOKED BUSY: ",
                            //     moment.utc(item.startTime).format("HH:mm")
                            //   );
                        }
                    });
                }
                isWork = isOnline
                    ? totalBooked == 0
                        ? isWork
                        : false
                    : totalBooked < 3
                        ? isWork
                        : false;
                // if (totalBooked >= 3) {
                //   isWork = false;
                // }
            });
        }
        const finalTime = new moment(day).set({
            hour: time.hours(),
            minute: time.minutes(),
        });
        const duration = moment.duration(finalTime.diff(moment())).asMinutes();
        // console.log("DURATION: ", duration, isOnline)
        // if (
        //     (!isOnline && duration < 60 * 24 && duration > 0) ||
        //     (isOnline && duration < 10 && duration > 0)
        // )
        //     isWork = false;
        workhour.push({ time: finalTime, work: isWork });

        //Tang moc thoi gian sang moc tiep theo de thuc hien tinh toan lai
        time.add(timeStep, "minute");

        //recount min/max time availabel
        if (isWork) foundAvailabelTime = true;
        else if (!foundAvailabelTime) minTimeIndex = i;
        if (foundAvailabelTime && isWork) maxTimeIndex = i;
    }

    // console.log("====================== day: ", day.format('DD-MM-YYYY'))
    // console.log("Min INdex: ", minTimeIndex)

    return { workhour, minTimeIndex, maxTimeIndex };
};

export const getListDoctorAvailableOnDay = (
    doctors,
    day,
    isOnline,
    brandId
) => {
    const listAvailable = [];

    if (!doctors || !Array.isArray(doctors)) return listAvailable;

    doctors.forEach((doctor) => {
        const workConfig = isOnline
            ? doctor.workHoursOnline.nodes
            : doctor.workHoursOffline.nodes;
        const offConfig = isOnline
            ? doctor.dayOffOnline.nodes
            : doctor.dayOffOffline.nodes;

        const workhour = getWorkInDay(
            workConfig,
            offConfig,
            doctor.datetimeBooked.nodes,
            day,
            15,
            brandId
        );
        // eslint-disable-next-line eqeqeq
        if (workhour.maxTimeIndex != 0) {
            const earlyTime = workhour.workhour[workhour.minTimeIndex + 1].time;
            let fDoctor = { ...doctor };
            day.set({
                hour: earlyTime.get("hour"),
                minute: earlyTime.get("minute"),
                second: earlyTime.get("second"),
            });
            // earlyTime.set("date", day.days())
            fDoctor.nextAvailableTime = day.format();
            listAvailable.push(fDoctor);
        }
    });

    return listAvailable;
};
