import React, { useState, useEffect } from 'react';
import * as ApiHelper from '../common/ApiHelper'
import * as QUERY from '../common/Queries'

// export const useGetClinics = (props) => {

//     console.log('----props',props)
//     const { major } = useParams()

//     const servicesList = useGetServices()

    
//     const [clinics, setClinics] = useState(null)
//     useEffect(() => {
//         if(servicesList) {
//             const serviceList = props.isOnline ? servicesList.listServiceOnline : servicesList.listServiceOffline
//             if(serviceList ) {
//                 const serviceSelected = serviceList.find(t=>t.id === Number(major))
//                 if(serviceSelected )
//                 console.log('----serviceSelected', serviceSelected)
//                 const params = { brandId: serviceSelected.brandId, serviceId: serviceSelected.id, specialityIds: serviceSelected.specialityIds}
//                 collectClinic(params);
//             }
//         }
//         return () => { };
//     }, [servicesList])

//     const collectClinic = async (params) => {
//         // const params = {brandId:brandId, serviceId: serviceId, specialityIds: specialityIds}
//         const res = await ApiHelper.sendGraphQL(QUERY.GET_CLINICS,params)
//         if (res) {
//             setClinics(res)
//         } else setClinics([])
//     }
//     return clinics
// }

export const useGetServices = () => {
    const [services, setServices] = useState(null)

    useEffect(() => {
        collectServices();
        return () => { };
    }, [])

    const getServiceOfSpecialist = (specialists, services) => {
        let listSpecialistHaveService = [];
        specialists.map(specialist => {
            let servicesOfSpecialist = services.filter(service => {
                let isHasInspecialist = service?.specialityIds?.indexOf(specialist?.id) > -1 //.nodes.find(el => el.id === specialist.id)
                return isHasInspecialist
            })
            if (servicesOfSpecialist?.length) listSpecialistHaveService.push({ ...specialist, services: servicesOfSpecialist })
        })
        return listSpecialistHaveService
    }

    const collectServices = async () => {
        const res = await ApiHelper.sendGraphQL(QUERY.GET_ONLINE_SERVICES, null)
        if (res) {
            const services = res?.mainServices
            const specialities = res?.specialities

            if (!Array.isArray(services) || !Array.isArray(specialities)) {
                setServices([])
                return;
            }

            const listServiceOnline = [], listServiceOffline = [];

            services.map(service => {
                if (service.doHomeService) {
                    listServiceOnline.push(service)
                } else {
                    listServiceOffline.push(service)
                }
            })

            let listSpecialistOnline = specialities;
            let listSpecialistOffline = specialities

            listSpecialistOnline = getServiceOfSpecialist(listSpecialistOnline, listServiceOnline)
            listSpecialistOffline = getServiceOfSpecialist(listSpecialistOffline, listServiceOffline)

            const data = {
                listServiceOnline,
                listServiceOffline,
                listSpecialistOnline,
                listSpecialistOffline
            }
            setServices(data);
        } else setServices([])
    }

    return services;
}

export const useGetListDoctor = (clinicId, brandId, isOnline, serviceId) => {
    const [doctors, setDoctors] = useState(null)

    useEffect(() => {
        console.log("======== START GET DOCTOR: ", clinicId, brandId, isOnline, serviceId);
        if (serviceId && (isOnline || (isOnline==false && clinicId))) {
            console.log("======== START GET DOCTOR: ", clinicId, brandId, isOnline, serviceId);
            getDoctors();
        }
        return () => { }
    }, [clinicId, brandId, isOnline, serviceId])

    const getDoctors = async () => {
        const params = { serviceId: parseInt(serviceId), doHomeService: isOnline }
        if (clinicId) params.clinicId = clinicId;
        if (brandId) params.brandId = brandId;
        const res = await ApiHelper.sendGraphQL(QUERY.GET_LIST_DOCTOR, [params])
        console.log("======== DOCTOR: ", res);
        if (res) {
            let validDoctor = []
            res.forEach(doctor => {
                if (doctor.nextAvailableTime != null)
                    validDoctor.push(doctor)
            });
            setDoctors(validDoctor)
        }

    }
    return doctors;
}
