import { Avatar, Col, Popover, Row, Space, Spin, Tabs, Tag } from 'antd';
import { useContext, useState, useEffect } from 'react';
import { ServiceContext } from './common/AppContext';
import { getTimeAvailable, convertChar } from './common/Converter'
import CServiceItem from './common/CServiceItems';
import { useGetListDoctor, useGetServices } from './hook/ServiceHook'
import moment from 'moment';
import CDoctorProfile from './common/CDoctorProfile';

const timeStep = 15;
const { TabPane } = Tabs;

export default function ListDoctorComponent({ service }) {
    const foundDoctors = useGetListDoctor(null, null, true, service);
    // const [doctors, setDoctors] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [schedule, setSchedule] = useState([])
    const [daysConfig, setDaysConfig] = useState([]);
    const [timeMilestone, setTimeMilestone] = useState([]);
    useEffect(() => {
        let start = moment().startOf("day").add(7, 'hours');
        let steps = []
        for (var i = 0; i < 67; i++) {
            start.add(15, 'minutes');
            steps.push(start.format('HH:mm'))
        }
        setTimeMilestone(steps)
    }, [])

    useEffect(() => {
        if (service) {
            console.log("START LOADING")
            setIsLoading(true)
        }
    }, [service])

    useEffect(() => {
        if (foundDoctors && Array.isArray(foundDoctors)) {
            setIsLoading(false)
            // setDoctors(foundDoctors)
            setSchedule([])
            let days = [];
            for (var i = 0; i < 14; i++) {
                const day = moment().add(i, 'days');
                days.push(day);
                console.log('DAYS: ', day.day() + ' - ' + day.format('DD/MM/YYYY'))
            }
            setDaysConfig(days);
            let timeAvailable = {}
            // console.log(" ===> DOCTORS: ", foundDoctors);
            for(var k=0;k<foundDoctors.length;k++){
                const doctor = foundDoctors[k]
                // console.log(" - DOCTOR : ", doctor);
                if (doctor) {
                    const workhour = getTimeAvailable(doctor, days, true, timeStep)
                    console.log("WORK TIME OF DOCTOR: ", workhour);
                    for (var i = 0; i < days.length; i++) {
                        for (var j = 0; j < workhour[i].length; j++) {
                            const workTime = workhour[i][j];
                            if (workTime.work) {
                                const timeStr = workTime.time.format('HH:mm')
                                if (timeAvailable[i]) {
                                    if (timeAvailable[i][timeStr]) {
                                        const doctorIndex = timeAvailable[i][timeStr].findIndex(t => t.id === doctor.id)
                                        if (doctorIndex == -1)
                                            timeAvailable[i][timeStr].push(doctor)
                                    } else timeAvailable[i][timeStr] = [doctor]
                                } else {
                                    const timeObj = {}
                                    timeObj[timeStr] = [doctor]
                                    timeAvailable[i] = timeObj
                                    // console.log("NEW TIME AVAILABLE: ", timeAvailable[i]);
                                }
                            }
                        }
                    }
                }
            };

            // console.log("TIME AVAILABLE: ", timeAvailable);
            setSchedule(timeAvailable)
        }
    }, [foundDoctors])


    return (
        <div style={{ width: '100%', textAlign: 'left', color:'white'}}>
            <span style={{ zIndex: 20, marginBottom: '10px', width: '100%', fontWeight:600, color:'white' }}>DANH SÁCH BÁC SĨ </span>
            {
                isLoading ? <Spin /> :
                    <Tabs
                        // tabBarExtraContent={ControlButton}
                        tabBarStyle={{ padding: 0 }}
                        // centered={true}
                        tabBarGutter={40}>
                        {
                            daysConfig.map((day, key) => <TabPane
                                tab={`${day.format('ddd DD/MM')}`}
                                key={key}>
                                <Space direction='vertical' align='start' size={0}>
                                    {
                                        timeMilestone.map((step, stepIndex) => {
                                            let listDoctor = []
                                            if (schedule[key] && Array.isArray(schedule[key][step]))
                                                listDoctor = schedule[key][step]

                                            return (
                                                <Space key={stepIndex} style={{ backgroundColor: `${stepIndex % 2 == 0 ? '#efffff' : 'white'}`, padding: '8px' }}>
                                                    <Tag color="#108ee9" style={{ fontWeight: 'bold' }}>{step}</Tag>
                                                    {
                                                        listDoctor.map((doctor, doctorIndex) => {
                                                            return (
                                                                <Space key={doctorIndex}>
                                                                    <Popover content={() => <CDoctorProfile doctor={doctor} />}>
                                                                        <Avatar src={doctor?.photo?.thumb ? doctor.photo.thumb : null} size={25} >
                                                                            {doctor?.photo?.thumb ? "" : doctor?.name ? doctor.name.substring(0, 2) : "B"}
                                                                        </Avatar>
                                                                    </Popover>
                                                                    <div>{doctor.name}</div>
                                                                </Space>
                                                            )
                                                        })
                                                    }
                                                </Space>
                                            )
                                        })
                                    }
                                </Space>
                            </TabPane>)
                        }
                    </Tabs>


            }
        </div >
    )
}