import axios from 'axios';
import { CMS_API } from './Constant'

function buildArgs(argsObj) {
    var args = []
    if (typeof argsObj === 'string')
        return argsObj
    else
        for (var argName in argsObj) {
            // console.log("---- Property name =====: ", argName)
            if ((argName === 'status' || argName === 'statuses') && Array.isArray(argsObj[argName])) {
                let temp = argsObj[argName]
                temp = temp.toString()
                args.push(argName + ": " + `[${temp}]`);
            } else if (['status', 'examinationType', 'commentType', 'bloodGroup', 'paramsMutation', 'photos', 'cancelReasonType'].includes(argName)) {
                let temp = argsObj[argName]
                temp = temp.toString()
                args.push(argName + ": " + `${temp}`);
            } else if (argName === 'allergies' && Array.isArray(argsObj[argName])) {
                const allergies = argsObj[argName]
                let list = []
                allergies.forEach(item => {
                    let temp = []
                    for (var key in item) {
                        if (key === 'allergyType' || key === 'category') {
                            temp.push(key + ": " + `${item[key]}`);
                        } else {
                            temp.push(key + ": " + JSON.stringify(item[key]));
                        }
                    }
                    list.push(`{${temp.join(', ')}}`)
                });
                args.push(`allergies: [${list.join(', ')}]`)
            } else if (argsObj[argName] && typeof argsObj[argName] === 'object' && argsObj[argName].type === 'enum' && argsObj[argName].value) {
                let temp = argsObj[argName].value
                temp = temp.toString()
                args.push(argName + ": " + `${temp}`);
            } else {
                args.push(argName + ": " + JSON.stringify(argsObj[argName]));
            }
        }
    return args.join(', ')
}

export const sendGraphQL = async (queryObj, params, token) => {
    let queryString = queryObj.query;
    if (params) {
        if (Array.isArray(params) && params.length > 0) {
            params.forEach((param, index) => {
                // console.log("PARAM: ", index + ': ' + param + '  - ' + buildArgs(param))
                queryString = queryString.replace(`{${index}}`, buildArgs(param));
            })
        } else queryString = queryString.replace('{0}', buildArgs(params));
    }

    const options = {
        url: process.env.REACT_APP_CMS_API + '/graphql',
        method: 'POST',
        dataType: 'json',
        headers: {
            "Access-Control-Allow-Origin": "*",
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
            'appVersion': '2.22.1.1'
        },
        data: {
            "query": queryString,
            "variables": { "app": "web", "token": process.env.REACT_APP_DEV_MOD == 'false' ? token : "eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiI0MzVkZmQ0Yi05OTUwLTRjNGYtODllMC03ODg2N2QzNTlkNGYiLCJzdWIiOiIyOTkyIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjM3NzY3OTYyLCJleHAiOjE2NDEzOTY3NjJ9.SN8iyW5hTh8-YpHtRdSWTm0ifxo11R60QTfgt2lHegY" }
        },
    };

    try {
        let res = await axios(options);
        if (res.status === 200 && res.data) {
            if (!res.data) return res;
            if (!res.data.data) return res.data;

            if (Array.isArray(queryObj.responseName) && queryObj.responseName.length > 0) {
                let responseData = {}
                queryObj.responseName.forEach((item) => {
                    if (res.data.data[item]) responseData[item] = res.data.data[item];
                })
                return responseData;
            } else if (res.data.data[queryObj.responseName]) {
                return res.data.data[queryObj.responseName]
            }
        }
    } catch (err) {
        console.error(err);
    }

    return null;
}
export const sendGraphQL_FileUpload = async (queryObj, params, token, photos) => {
    let queryString = queryObj.query;
    if (params) {
        if (Array.isArray(params) && params.length > 0) {
            params.forEach((param, index) => {
                // console.log("PARAM: ", index + ': ' + param + '  - ' + buildArgs(param))
                queryString = queryString.replace(`{${index}}`, buildArgs(param));
            })
        } else queryString = queryString.replace('{0}', buildArgs(params));
    }

    const config = {
        url: process.env.REACT_APP_CMS_API + '/graphql',
        method: 'POST',
        dataType: 'json',
        headers: {
            "Access-Control-Allow-Origin": "*",
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
            'appVersion': '2.22.1.1'
        },
        params: {
            query: queryString
        },
        data: {
            "query": queryString,
            "variables": { "app": "web", "token": process.env.REACT_APP_DEV_MOD == 'false' ? token : "eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiI0MzVkZmQ0Yi05OTUwLTRjNGYtODllMC03ODg2N2QzNTlkNGYiLCJzdWIiOiIyOTkyIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjM3NzY3OTYyLCJleHAiOjE2NDEzOTY3NjJ9.SN8iyW5hTh8-YpHtRdSWTm0ifxo11R60QTfgt2lHegY" }
        },
    };
    if ((photos && photos?.length) || photos?.path) {
        config.params.app = 'web'
        config.params.token = process.env.REACT_APP_DEV_MOD == 'false' ? token : "eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiI0MzVkZmQ0Yi05OTUwLTRjNGYtODllMC03ODg2N2QzNTlkNGYiLCJzdWIiOiIyOTkyIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjM3NzY3OTYyLCJleHAiOjE2NDEzOTY3NjJ9.SN8iyW5hTh8-YpHtRdSWTm0ifxo11R60QTfgt2lHegY";
        let fd = new FormData()
        let map = {}

        if (Array.isArray(photos) && photos.length > 0) {
            config.params.variables = {
                photos: Array(photos.length).fill(null)
            }
            for (let i = 0; i < photos.length; i++) {
                const photo = photos[i];
                fd.append(i, photo)
                map[i] = [`variables.photos.${i}`]
            }
        } else if (photos && !Array.isArray(photos)) {
            config.params.variables = {
                photo: null
            }
            fd.append(0, photos)

            map = { 0: [`variables.photo`] }
        }

        fd.append('operations', JSON.stringify(config.params))
        if (map) {
            fd.append('map', JSON.stringify(map))
        }

        config.headers['Content-Type'] = "multipart/form-data"
        config.data = fd
    }

    try {
        let res = await axios(config);

        if (res.status === 200 && res.data) {
            if (!res.data) return res;
            if (!res.data.data) return res.data;

            if (Array.isArray(queryObj.responseName) && queryObj.responseName.length > 0) {
                let responseData = {}
                queryObj.responseName.forEach((item) => {
                    if (res.data.data[item]) responseData[item] = res.data.data[item];
                })
                return responseData;
            } else if (res.data.data[queryObj.responseName]) {
                console.log("RESPONSE: ", res.data);
                return res.data.data[queryObj.responseName]
            }
        }
    } catch (err) {
        console.error(err);
    }

    return null;
}